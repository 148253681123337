import OpenAPIClientAxios from "openapi-client-axios";

export class RestApi {
  constructor() {
      console.log("constructing RestAPI");
    this.api = new OpenAPIClientAxios({
      definition,
      axiosConfigDefaults: {
        baseURL: process.env.VUE_APP_API_URL,
      //   auth: {
      //     username: "uuid_philip",
      //     password: "test",
      //   },
        headers: {
          common: {
            Authorization: "Bearer " + localStorage.authtoken, // set by Auth.vue which will be shown on every 401 (Not authenticated) response
          },
        },

        withCredentials: true,
      },
    });
  }

  initClient() {
    const client = this.api.initSync();
    client.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );
    return client;
  }

  errorHandler(error) {
    if (error?.response?.data?.name && error?.response?.data?.message)
      console.error(
        "API:",
        error.response.data.name,":",
        error.response.data.message,":",
        error.response,
      );
    error.name = "API Error";
    if (error.response.status == 401) {
      // if Authentication Error: Show Auth Component Auth.vue
      localStorage.setItem("authtoken", null);
      let event = new Event("toggleAuth", { bubbles: true });
      document.dispatchEvent(event);
       // If 400 (e.g. InvalidError etc): Show toast     
      let event2 = new CustomEvent("triggerToast", {
         bubbles: true, 
         detail:{ toasttype: "error", toastmessage:error.response.data.message} });
      document.dispatchEvent(event2);
    }
    if (error.response.status == 400) {
      console.log("400 error",error.response.data);
      // If 400 (e.g. InvalidError etc): Show toast
      let event = new CustomEvent("triggerToast", { 
         bubbles: true, 
         detail:{ toasttype: "error", toastmessage:error.response.data.message} });
      document.dispatchEvent(event);
    }   
    if (error.response.status == 403) {
       // If ForbiddenError: Show toast
       let event = new CustomEvent("triggerToast", { 
          bubbles: true, 
          detail:{ toasttype: "error", toastmessage:error.response.data.message} });
       document.dispatchEvent(event);
    }
    return Promise.reject(error);
  }

  successHandler(response) {
    //console.log("success",response);
    return response;
  }
}

// TODO: dynamically load process.env.VUE_APP_API_DEFINITION;
const definition = {
    "openapi": "3.0.1",
    "info": {
        "title": "dingsda 2meX api",
        "version": "1.0.1",
        "description": "This is to become the new API for dingsda_2meX.",
        "license": {
            "name": "Licensed Under MIT",
            "url": "https://spdx.org/licenses/MIT.html"
        },
        "contact": {
            "name": "machina commons",
            "url": "https://dingsda.machinacommons.world/api"
        }
    },
    "servers": [
        {
            "url": "http://localhost:8080/api",
            "description": "Development server"
        }
    ],
    "components": {
        "schemas": {
            "user": {
                "type": "object",
                "required": [
                    "id",
                    "email",
                    "name",
                    "password"
                ],
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "password": {
                        "type": "string"
                    }
                }
            },
            "item": {
                "allOf": [
                    {
                        "type": "object",
                        "required": [
                            "id"
                        ],
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    {
                        "$ref": "#/components/schemas/newItem"
                    }
                ]
            },
            "newItem": {
                "type": "object",
                "required": [
                    "name"
                ],
                "properties": {
                    "name": {
                        "type": "string"
                    },
                    "description": {
                        "type": "string"
                    },
                    "tags": {
                        "type": "array"
                    },
                    "insideof": {
                        "type": "string"
                    },
                    "location": {
                        "type": "object"
                    }
                },
                "example": {
                    "name": "pappkarton rot",
                    "description": "karton mit requisiten drin",
                    "insideof": "4",
                    "tags": [
                        "pappe",
                        "box",
                        "requisiten"
                    ]
                }
            }
        }
    },
    "paths": {
        "/signup": {
            "post": {
                "operationId": "signup",
                "description": "sign up to create a new user",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/user"
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/login": {
            "post": {
                "operationId": "login",
                "description": "dedicated login endpoint to test login. Login will be done via request headers, depending on activated login strategy on server",
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/upload": {
            "post": {
                "operationId": "uploadFiles",
                "description": "accepts multipart/form-data to upload files. files will only be accepted if user exists and user has existing directory under /data/{userId}/",
                "requestBody": {
                    "content": {
                        "multipart/form-data": {
                            "schema": {
                                "type": "object",
                                "properties": {
                                    "profileImage": {
                                        "type": "string",
                                        "format": "binary"
                                    }
                                }
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/upload/{userId}/{fileName}": {
            "get": {
                "operationId": "getUploadedFile",
                "description": "get file (most likely image) that was uploaded by user with userId. Here all all uploaded images endup when new items are created and the dingsda 2mex server allows direct fileuploads by users",
                "parameters": [
                    {
                        "in": "path",
                        "name": "userId",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "path",
                        "name": "fileName",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items": {
            "get": {
                "operationId": "getItems",
                "description": "list of all items",
                "parameters": [
                    {
                        "in": "query",
                        "name": "all",
                        "description": "if set to \"true\" all visible items will be shown instead of only items owned or possessed by the user and their groups",
                        "schema": {
                            "type": "boolean"
                        }
                    },
                    {
                        "in": "query",
                        "name": "lastItemOnPage",
                        "required": false,
                        "schema": {
                            "type": "string"
                        },
                        "description": "the id of the last item in the list returned after the last searchRequest with same search parameters."
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "put": {
                "operationId": "addItem",
                "description": "create new item",
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                },
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/newItem"
                            }
                        }
                    }
                }
            },
            "post": {
                "operationId": "updateItems,",
                "description": "update an array of existing items;",
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/{item}/_contents": {
            "get": {
                "operationId": "getItemContents",
                "description": "list of all items inside of the item.",
                "parameters": [
                    {
                        "in": "path",
                        "name": "item",
                        "description": "itemId of container",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/{item}/_ask2borrow": {
            "get": {
                "operationId": "ask2borrow",
                "description": "ask to borrow item. will return items owners contact details if they exist",
                "parameters": [
                    {
                        "in": "path",
                        "name": "item",
                        "description": "itemId of item requested for borrowing",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/{item}/_handover": {
            "post": {
                "operationId": "handoverItem",
                "description": "handover item (that you are owner of) to another owner or a group of owners",
                "parameters": [
                    {
                        "in": "path",
                        "name": "item",
                        "description": "itemId of item requested for borrowing",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "to",
                        "description": "username or array of usernames to hand the item over to.",
                        "required": true,
                        "schema": {
                            "oneOf": [
                                {
                                    "type": "string"
                                },
                                {
                                    "type": "array"
                                }
                            ]
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_search": {
            "get": {
                "operationId": "searchItems",
                "description": "search for tags (by pattern) and for item names (by fulltext search) from just one string parameter. intended to be used for autofill features in the item name fields when creating new items. response will be paginated to 50 items. to get the next page, provide the id of the last item received as 'lastItemOnPage'",
                "parameters": [
                    {
                        "in": "query",
                        "name": "text",
                        "required": true,
                        "schema": {
                            "type": "string"
                        },
                        "description": "the text to be searched for in item names as well as existing tags"
                    },
                    {
                        "in": "query",
                        "name": "lastItemOnPage",
                        "required": false,
                        "schema": {
                            "type": "string"
                        },
                        "description": "the id of the last item in the list returned after the last searchRequest with same search parameters."
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_searchByName": {
            "get": {
                "operationId": "searchItemsByName",
                "description": "search items by name only. will return an object with all items found as well as all distinct tags used by these items",
                "parameters": [
                    {
                        "in": "query",
                        "name": "text",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "tags",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "description": "Array of tags that should match (AND)"
                        }
                    },
                    {
                        "in": "query",
                        "name": "containers",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "description": "Array of container ids that should match (OR)"
                        }
                    },
                    {
                        "in": "query",
                        "name": "area",
                        "required": false,
                        "schema": {
                            "type": "object",
                            "description": "geojson feature object that should intersect (AND)"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_update": {
            "post": {
                "operationId": "updateItems",
                "description": "update all items and return array of successes, denials and reasons for denials",
                "parameters": [
                    {
                        "in": "query",
                        "name": "items",
                        "description": "array of item objects",
                        "schema": {
                            "type": "array"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_move": {
            "post": {
                "operationId": "moveItems",
                "description": "move all items and return array of successes, denials and reasons for denials",
                "parameters": [
                    {
                        "in": "query",
                        "name": "items",
                        "description": "array of item objects",
                        "schema": {
                            "type": "array"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_geosearch": {
            "get": {
                "operationId": "locateItems",
                "description": "get all items within a circle described by latitude, longitude and radius.",
                "parameters": [
                    {
                        "in": "query",
                        "name": "latitude",
                        "required": true,
                        "schema": {
                            "type": "number"
                        },
                        "description": "latitude of center point of search"
                    },
                    {
                        "in": "query",
                        "name": "longitude",
                        "required": true,
                        "schema": {
                            "type": "number"
                        },
                        "description": "longitude of center point of search"
                    },
                    {
                        "in": "query",
                        "name": "radius",
                        "required": true,
                        "schema": {
                            "type": "number"
                        },
                        "description": "search radius in meters around center point of search."
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/_rootContainer": {
            "get": {
                "operationId": "getAllRootContainers",
                "description": "get all possible root containers of user sending this query. works also from /places/_rootContainer",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/item"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/items/{itemID}": {
            "get": {
                "operationId": "getItem",
                "parameters": [
                    {
                        "in": "path",
                        "name": "itemID",
                        "required": true,
                        "schema": {
                            "type": "string"
                        },
                        "description": "The item ID"
                    }
                ],
                "description": "get single item entry",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/item"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "operationId": "updateItem",
                "description": "update existing item",
                "parameters": [
                    {
                        "in": "path",
                        "name": "itemID",
                        "required": true,
                        "schema": {
                            "type": "string",
                            "description": "The item ID"
                        }
                    },
                    {
                        "in": "query",
                        "name": "changes",
                        "schema": {
                            "type": "object"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "delete": {
                "operationId": "deleteItem",
                "description": "delete item",
                "parameters": [
                    {
                        "in": "path",
                        "name": "itemID",
                        "required": true,
                        "schema": {
                            "type": "string",
                            "description": "The item ID"
                        }
                    },
                    {
                        "in": "query",
                        "name": "changes",
                        "schema": {
                            "type": "object"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/items/{itemID}/_rootContainer": {
            "get": {
                "operationId": "getItemRootContainer",
                "parameters": [
                    {
                        "in": "path",
                        "name": "itemID",
                        "required": true,
                        "schema": {
                            "type": "string"
                        },
                        "description": "The item ID"
                    }
                ],
                "description": "get single item entry",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/item"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/tags/{tag}": {
            "get": {
                "operationId": "searchTags",
                "description": "search for already existing tags within the database. intended to be used for autocompletes in clients.",
                "parameters": [
                    {
                        "in": "path",
                        "name": "tag",
                        "description": "search pattern. must be at least 3 characters or longer.",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "ok"
                    }
                }
            }
        },
        "/places": {
            "get": {
                "operationId": "getPlaces",
                "description": "get AllPlaces, optionally filtered by query parameters",
                "parameters": [
                    {
                        "in": "query",
                        "name": "latitude",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "longitude",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "radius",
                        "required": false,
                        "schema": {
                            "type": "number"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "put": {
                "operationId": "createPlace",
                "description": "create a place by providing a geojson shape describing the place. If no name is provided, a name will be given by reverse geosearch.",
                "parameters": [
                    {
                        "in": "query",
                        "name": "geojson",
                        "required": true,
                        "schema": {
                            "type": "object"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/places/{placeID}": {
            "get": {
                "operationId": "getPlace",
                "description": "get place by its ID",
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/sql/search": {
            "get": {
                "operationId": "searchDBFullText",
                "description": "search database with full text search, deactivated",
                "parameters": [
                    {
                        "in": "query",
                        "name": "table",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "column",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "searchquery",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/sql/pattern": {
            "get": {
                "operationId": "searchDBPattern",
                "description": "search database by pattern, deactivated",
                "parameters": [
                    {
                        "in": "query",
                        "name": "table",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "column",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "searchquery",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/groups/myself": {
            "get": {
                "operationId": "getUserConfig",
                "description": "get user config. at the moment only name(pseudonym), email and public_contact_details are returned",
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "post": {
                "operationId": "updateUserConfig",
                "description": "update user or group config of user/group authenticated. At the moment only name(pseudonym), email and public_contact_details are allowed",
                "parameters": [
                    {
                        "in": "query",
                        "name": "config",
                        "required": true,
                        "schema": {
                            "type": "object"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/groups/myself/members": {
            "post": {
                "operationId": "addMemberToMyself",
                "description": "add a member to the group/user account defined by authentication",
                "parameters": [
                    {
                        "in": "query",
                        "name": "memberid",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "delete": {
                "operationId": "deleteMemberFromMyself",
                "description": "remove a member from the group/user account defined by authentication",
                "parameters": [
                    {
                        "in": "query",
                        "name": "memberid",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        },
        "/printer/{id}/_queue": {
            "get": {
                "operationId": "getPrinterQueue",
                "description": "get queue of printer with id. printer needs to be owned by the user to return.",
                "parameters": [
                    {
                        "in": "path",
                        "name": "id",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            },
            "put": {
                "operationId": "setPrinterQueue",
                "description": "set queue of printer with id. printer needs to be owned by the user to return.",
                "parameters": [
                    {
                        "in": "path",
                        "name": "id",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "in": "query",
                        "name": "prints",
                        "required": true,
                        "schema": {
                            "type": "array"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    }
                }
            }
        }
    }
}