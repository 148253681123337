<template>
  <div>
    <!--<l-map ref="map" v-model:zoom="zoom" :center="[48.904612356,9.19594345]">-->
    <!--{{mapcenter}}-->
    <l-map style="min-height:100px" ref="map" :zoom="zoom" :center="mapcenter" @movestart="mapMoving = true" @moveend="endMapMoving" @click="handleMapClicked">
      <l-tile-layer
        :url="tileproviderurl"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-marker v-if="marker && marker[0] && marker[1]" :lat-lng="[marker[0], marker[1]]" draggable @moveend="handleMarkerMoved" >
      </l-marker>
      <l-geo-json :geojson="area" ref="geojsonRef"></l-geo-json>
    </l-map>
  </div>
</template>

<script>
// DON'T load Leaflet components here! // TODO: read up on this and check if leaflet itself should also be imported from beforeMount()
// Its CSS is needed though, if not imported elsewhere in your application.
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LGeoJson, LMarker } from "@vue-leaflet/vue-leaflet";
import config from "@/../config.json";
import {computed,onBeforeMount,ref, watch} from "@vue/runtime-core";
//import leaflet from "leaflet"; // TODO: optimize loading behaviour

import turfcenter from '@turf/center';
import turfflip from '@turf/flip';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
  },
  props:["center","area","zoom","marker"],
  emits:["mapClicked","centerMoved","markerMoved"],
  setup(props,{emit}) {
    // config.json should include the key to a mapprovider. we use (for example) maptiler.
    const tileproviderKey = config.maptilerKey; 
    const tileproviderurl = `https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${tileproviderKey}`;

    const getCenterOfPolygon = (polygon) => {
      console.log("polygon",polygon)
      polygon = turfflip(polygon);
      let center = turfcenter(polygon) //new leaflet.Polygon(polygon).getBounds().getCenter();
      console.log("center",center);
      return center.geometry.coordinates
    }

    const mapcenterdefault = [52.51899816279893,13.389167537097821];
    const mapcenter = ref(mapcenterdefault);
    const mapMoving = ref(false);

    watch(computed(()=>props.area),()=>{
      console.log("props.area changed to",props.area);
      
      centerMap()
    });

    watch(computed(()=>props.marker),()=>{centerMap()});
    
    const centerMap = ()=>{
      if (!props.center && props.area){
        mapcenter.value = getCenterOfPolygon(props.area); // CONTINUE HERE
        //console.log("Map","setting automatic default center point of map",mapcenter.value);
      }
      else if(props.marker){
        let array = [props.marker[0],props.marker[1]]
        console.log("Map","centering on prop.marker",array);
        mapcenter.value = array;
      }
      else if (props.center){
        console.log("Map","center based on prop.center",props.center);
        mapcenter.value = computed(()=>props.center);
      }
      else{
        console.warn("centering of map not possible for neither props.center,props.area,props.marker present");
        mapcenter.value = mapcenterdefault
        
      }
      emit("centerMoved",mapcenter.value)
    }

    onBeforeMount(()=> {
      // HERE is where to load Leaflet components if needed!
      centerMap();
      // this.mapIsReady = true;
    })

    const handleMarkerMoved = (e)=>{
      console.log("marker moved",e);
      emit("markerMoved",e)
    }

    const handleMapClicked = (e)=>{
        if (mapMoving.value) return
        if(props.marker) return
        try{
          console.log("map clicked at Lat, Lng : " + e.latlng.lat + " , " + e.latlng.lng)
          emit("mapClicked",e)
        }catch(e){
          console.warn(e)
        }
    }

    const endMapMoving = ()=>{
      setTimeout(()=>{mapMoving.value = false},200)
    }

    return {
      tileproviderurl,
      mapcenter,
      handleMarkerMoved,
      handleMapClicked,
      mapMoving,
      endMapMoving,
    };
  },
};
</script>

<style>

</style>