<template>

  <back-button />
  <ItemDetail 
    class="addDing is-full-height is-overflow-y-scroll is-overflow-x-hidden" 
    v-if="$store.state.selected.item" 
    :item="$store.state.selected.item" 
  />
  
</template>

<script>
import ItemDetail from '@/components/Page/ItemDetail.vue'
import { useStore } from "vuex";
import {useRoute} from "vue-router";
import BackButton from '@/components/Template/BackButton.vue';
import { nextTick, onMounted,onUnmounted, watch } from '@vue/runtime-core';


export default {
  name: 'Detail',
  components: {
    ItemDetail,
    BackButton,
  },
  setup(){
    const store = useStore();
    const route = useRoute();
    //const item = computed(()=>store.state.selected.item)
    
    // react on route param changes with repicking of item (to make switching via storage pills possible)
    watch(()=>route.query.id, async () => {
      nextTick(async()=>{
        if (route.path != '/Detail') return // catch missfirings on page change
        console.log("id param changed to id",route.query.id, route.path);
        console.log("new id to pick",route.query.id);
        let picked = await store.dispatch("getItem",route.query.id);
        console.log("item picked:",picked);
        store.dispatch("pickItem",picked)
      })
    },
    {deep:true})


    onMounted(async()=>{
      store.dispatch("showTabBar");

      if (route.query.id){
        //console.log("current id to pick",route.query.id);
        let picked = await store.dispatch("getItem",route.query.id);
        store.dispatch("pickItem",picked)
      }
    })

    onUnmounted(()=>{
      
    })


    return {}
  }
}
</script>

<style scoped>

</style>
