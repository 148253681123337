<template>
  <div class="search-ding is-full-width is-full-height is-flex is-flex-direction-column">
    
    <div class="is-flex has-flex-direction-row is-full-width">
      <div class="spacer-empty" v-if="$route.query.rootContainer">
        <back-button v-if="$route.query.rootContainer"/>
      </div>
      <Autocomplete
                ref="searchTermInput"
                input-class="input left is-full-width"
                results-container-class="autocomplete-result-container"
                results-item-class="green-on-hover vue3-results-item"
                placeholder="search"
                :results="searchTermResults"
                @keypress.enter="handleSearchButtonPressed"
        ></Autocomplete>
        <button 
          class="button"
          @click="handleSearchButtonPressed($event)"
        >SEARCH</button>
    </div>

    <div class="is-flex is-flex-direction-row">
      <Pill 
        class="mt-2 ml-2 mb-0" 
        tag="Location" 
        @onTagClick="locationFilterVisible = !locationFilterVisible"
        hideDeleteButton="true"/>
      <div class=" mt-3 is-flex flex-direction-row is-flex-wrap-wrap">
        <div v-if="chosenArea">
           <Pill class="mt-0 ml-2 mb-0 geoLocationTag rootStorageTag taghead" 
              :tag="
                `${chosenArea.center.geometry.coordinates[0].toString().substring(0, 7)}.. , 
                ${chosenArea.center.geometry.coordinates[1].toString().substring(0, 7)}.. , 
                ${chosenArea.radius}m rad.`"
              @onDeleteClick="$store.dispatch('removeSearchFilterGeolocation')"/>
        </div>
        <div v-for="chosen in chosenLocations" :key="chosen">
          <Pill class="mt-0 ml-2 mb-0 geoLocationTag rootStorageTag taghead" :tag="chosen.name"
                @onDeleteClick="$store.dispatch('removeSearchFilterStorage',{filter:chosen})"/>
        </div>
      </div>
    </div>

    <SearchFilterLocation 
      v-if="locationFilterVisible" class="is-fixed is-z-2 is-full-height is-full-width"
      :possibleRootContainers="possibleRootContainers"
      :chosenLocations="chosenLocations"
      @close="onSearchLocationFilterClose"
    />

    <div class="is-flex flex-direction-row is-full-width">
      <Pill v-if="searchResults.length > 1 || searchResultTags.length > 1" class="ml-2" tag="Filters" 
      @onTagClick="searchFilterVisible = !searchFilterVisible" hideDeleteButton="true"/>
       <div class="is-flex flex-direction-row is-flex-wrap-wrap">
        <div v-for="chosen in chosenFilters" :key="chosen">
          <Pill class="m-1 mt-3 tag-small" :tag="chosen.tag"
                @onDeleteClick="$store.dispatch('removeSearchFilter',{filter:chosen})"/>
        </div>
      </div>
    </div>

    <!-- TODO: TagFilter Component instead of Collapsible -->
    <div v-if="searchFilterVisible" class="is-fixed  is-z-2 is-full-height is-full-width 
    is-overflow-y-scroll searchfilter-page has-background-purple-5 is-flex is-flex-direction-column">
        
        <Icon class="is-fixed is-right-0 is-top-0 is-size-4 has-text-dark m-5" 
        :icon="['far', 'times-circle']" @click="onSearchFilterClose"/>
    
        <div class="is-full-width">
          <h1 class="m-2 ml-4 has-text-weight-bold is-size-5 is-float-left">Filters</h1>
        </div>

        <h5 class="is-size-7 ml-2 is-text-align-start"> Weight </h5>
        <Slider label="min" step="0.5" min="0" max="200" v-model="minWeight" unit="kg"/>
        <Slider label="max" step="2" min="0" max="999" v-model="maxWeight" unit="kg"/>
       
        <h5 class="is-size-7 ml-2 is-text-align-start"> Length </h5>
        <Slider label="min" step="5" min="0" max="1000" v-model="minLength" unit="cm"/>
        <Slider label="max" step="5" min="0" max="10000" v-model="maxLength" unit="cm"/>

        <h5 class="is-size-7 ml-2 is-text-align-start"> Width </h5>
        <Slider label="min" step="1" min="0" max="1000" v-model="minWidth" unit="cm"/>
        <Slider label="max" step="5" min="0" max="5000" v-model="maxWidth" unit="cm"/>
       
        <h5 class="is-size-7 ml-2 is-text-align-start"> Height </h5>
        <Slider label="min" step="1" min="0" max="1000" v-model="minDepth" unit="cm"/>
        <Slider label="max" step="5" min="0" max="5000" v-model="maxDepth" unit="cm"/>
       

         <h5 class="is-size-7 ml-2 is-text-align-start"> Tags / Specifics </h5>
        <div class="is-flex is-flex-direction-row is-flex-wrap-wrap ml-2">
            <div v-for="tag in searchResultTags" :key="tag.tag">
              <Pill 
              :class="{'activeFilter':!tagNotInChosenFilters(tag),'searchFilterTag ml-1 mt-1':true}" 
              :tag="tag.tag" 
              @onTagClick="toogleChosenFilterClick($event,tag)" 
              hideDeleteButton="true"/>
            </div>
        </div>
    </div>


    <div id="searchResults" class="search-results" ref="searchResultsBox" @scroll="trackscroll">
      <div class="list-item-container" v-for="item in searchResults" v-bind:key="item.id"> 
        <ListItem 
          v-if="item" 
          :item="item" 
          @click="handleItemClicked($event,item)" 
          :class="{
            'is-select-mode':selectModeOn, 
            'is-selected':selectedItems.find((i)=>i?.id == item?.id)
            }" 
            :removebutton="false" />
      </div>
      <div class="button" :class="{
        'has-background-pink-5':loadMoreItemsButton != '... more ...'}" 
        @click="searchNextPage"> {{loadMoreItemsButton}} </div>
      <div style="height:6rem"></div>
       
    </div>
  </div>

  
  <div :class="{'listButtonBar mb-1 is-flex is-flex-direction-column is-full-width':true,'is-select-mode':selectModeOn}">
    <div class="mb-1 is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
      <div>
        
      </div>
      <div>
        <button v-if="$store.state.data.searchResultItems.length > 0" :class="{'mr-2 button is-rounded has-background-purple-1 has-text-light':true,'has-background-purple-5 has-text-dark':selectModeOn}" @click="handleSelectClicked">{{selectModeOn ? "READY" : "SELECT"}}</button>        
      </div>
    </div>
    <div class="selectButtonContainer is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
      <div>
        <button class="ml-2 button is-rounded" v-if="selectModeOn" @click="handleSelectAllClicked">☑️ SELECT ALL</button> 
      </div>
      <div>
        <button class="mr-2 button is-rounded" v-if="selectModeOn && selectedItems.length > 0" @click="handleAdd2Basket">ADD TO BASKET</button>
      </div>
    </div>        
  </div>
  <div style="height: 11vh"></div>
  
</template>

<script>
import { computed, ref, onMounted, onActivated } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter} from "vue-router"

import Autocomplete from '@/components/Template/Autocomplete'
import Pill from '@/components/Template/Pill'
import ListItem from '@/components/Template/ListItem'
import SearchFilterLocation from '@/components/Page/SearchFilterLocation'
import Slider from '@/components/Template/Slider'
import BackButton from '@/components/Template/BackButton.vue';

//import {removeParameterFromURL} from '@/helpers'

import { isEqual } from "lodash";

export default {
  name: 'SearchItems',
  components: {
    Autocomplete,
    Pill,
    ListItem,
    SearchFilterLocation,
    Slider,
    BackButton
  },
  setup(){
    const store = useStore();

    const router = useRouter();

    const searchTermInput = ref(null);
    const searchTermResults = ref([]);
    const searchResultsCollapsed = false;
    const searchResults = computed(()=> store.state.data.searchResultItems);
    const searchResultTags = computed(()=> store.state.data.searchResultTags);
    const chosenFilters = computed(()=> store.state.data.searchResultFilter);
    const possibleRootContainers = ref(null);
    const chosenLocations = computed(()=> store.state.data.searchResultFilterStorage);
    let filterArchives = {chosenLocations:[],chosenFilters:[],numericalFilters:[null, null, null, null, null, null, null, null],chosenArea:null};
    const chosenArea = computed(()=> store.state.data.searchResultFilterGeolocation);
    
    // numeric filters min/max
    const minWeight = ref(null);
    const maxWeight = ref(null);

    const minLength = ref(null);
    const maxLength = ref(null);
    
    const minWidth = ref(null);
    const maxWidth = ref(null);
    
    const minDepth = ref(null);
    const maxDepth = ref(null);
    
    const selectModeOn = ref(false);
    const locationFilterVisible = ref(false);
    const searchFilterVisible = ref(false);
    const selectedItems = ref([]);

    const loadMoreItemsButtonDefaultValue = "... more ..."
    const loadMoreItemsButton = ref(loadMoreItemsButtonDefaultValue)

    const onSearchLocationFilterClose = () => {
      locationFilterVisible.value = false; 
      let changed = false;
      changed = changeInFilterArraysDetected(chosenLocations.value,"chosenLocations");
      changed = changeInAreaFilterDetected() || changed;
      if (changed) handleSearchButtonPressed()
    }

    const onSearchFilterClose = () => {
      searchFilterVisible.value = false; 
      let changed = false;
      changed = changeInFilterArraysDetected(chosenFilters.value,"chosenFilters");
      changed = changeInNumericalFiltersDetected() || changed;
      console.log("filter changes detected?:",changed);
      if (changed) handleSearchButtonPressed()
    }

    const changeInAreaFilterDetected = () => {
      if (!isEqual(filterArchives['chosenArea'], chosenArea.value)) {
        console.log("area filter changed",filterArchives['chosenArea'],chosenArea.value);
        filterArchives['chosenArea'] = Object.assign({},chosenArea.value); // CONTINUE HERE: compare 
        return true
      }
      return false
    }

    const changeInNumericalFiltersDetected = () => {
      let newValues = 
      [
        minWeight.value,maxWeight.value,minLength.value,maxLength.value,
        minWidth.value,maxWidth.value,minDepth.value,maxDepth.value
      ]
      return changeInFilterArraysDetected(newValues, "numericalFilters")
    }

    /**
     * checks if changed. if so: trigger search. then: do for other filters
    */
    const changeInFilterArraysDetected = (newArray,oldArrayName) =>{
      let oldArray = filterArchives[oldArrayName];
      if ( 
        newArray.length !== oldArray.length || 
        newArray.some((value, index) => value !== oldArray[index])){
          console.log("filter changed",newArray,oldArray);
          filterArchives[oldArrayName] = Array.from(newArray);
          return true
        }
    }

    const handleSelectClicked = () =>{
     console.log("select clicked");
      // activate SELECTION MODE
      selectModeOn.value = !selectModeOn.value;
      // empty selection
      selectedItems.value = [];
    } 

    const handleItemClicked = (event,item)=>{
      if (selectModeOn.value){
        return toggleItemSelect(item)
      }
      router.push(`/Detail?id=${item.id}`);
    }

    const handleSelectAllClicked = ()=>{
      if (selectedItems.value.length < store.state.data.searchResultItems.length){
        selectedItems.value = store.state.data.searchResultItems;
      }
      else{
        selectedItems.value = [];
      }
    }

    const handleAdd2Basket = async () =>{
      await store.dispatch('add2Cart',{items:selectedItems.value});
      
      selectedItems.value = [];
      selectModeOn.value = false;
    }

    function toggleItemSelect(item) {
      console.log("item selected or unselected")
      
      let index=selectedItems.value.findIndex((i) => i?.id===item?.id)
      if(index===-1) {
        selectedItems.value.push(item)
      }
      else {
        selectedItems.value.splice(index, 1)
      }
    }

    const tagNotInChosenFilters = (tag) => {
      return chosenFilters.value.findIndex((o)=>{return isEqual(o,tag)}) == -1;
    }

    const handleChosenFilterClick = (e,tag)=> {console.log("filter got clicked",e,tag)};

    const handleChosenFilterDeleteClick = (e,tag)=> {console.log("filter delete",e,tag); removeFilter(e,{tag})};

    const handleSearchButtonPressed = async()=>{
      loadMoreItemsButton.value = loadMoreItemsButtonDefaultValue;
      await store.dispatch("emptySearchResultItems")//clear searchResults
      let name = searchTermInput.value.searchText;
      findItemsByNameAndLocation({name,tags:chosenFilters.value,locations:chosenLocations.value, area: chosenArea.value })
    }

    const findItemsByNameAndLocation = async ({name,tags,locations,area})=>{
      let lastItemOnPage = store.state.data.searchResultItems[store.state.data.searchResultItems.length-1]?.id;
      console.log(
        "next page search for",name,
        "lastItemOnPage:",lastItemOnPage
      );
      if (!lastItemOnPage){
        resetScrollPosition(); // only if first page. 
      }
      scrollToLastPosition();
      console.log("findItemsByNameAndLocation",name,tags,locations,area,lastItemOnPage);
      if (name || tags.length > 0 || locations.length > 0 || area){
        return await store.dispatch("searchItems",{text:name,tags:tags.map((t)=>t.tag),
          minWeight:minWeight.value,maxWeight:maxWeight.value,
          minLength:minLength.value,maxLength:maxLength.value,
          minWidth:minWidth.value,maxWidth:maxWidth.value,
          minDepth:minDepth.value,maxDepth:maxDepth.value,
          containers:locations.map((l)=>l.id), area,fillStoreWithResults:true,
          lastItemOnPage: lastItemOnPage,
          },);
      }
      if (!lastItemOnPage) await store.dispatch("triggerToast",{message:"NO searchparameters defined. Will fetch only user's items"})
      return await store.dispatch("searchAllMyItems");
    }

    const searchNextPage = async () =>{
      console.log("next page, please...");
      let name = searchTermInput.value.searchText;
      let moreItemsLoadable = await findItemsByNameAndLocation({name,tags:chosenFilters.value,locations:chosenLocations.value, area: chosenArea.value})
      if (!moreItemsLoadable) {
        console.log("NO MORE ITEMS");
        loadMoreItemsButton.value = "no more items to load"
      }
    }

    const toogleChosenFilterClick = async (e,tag)=>{
      console.log("toggle",tag);
      if (tagNotInChosenFilters(tag)){
        console.log("toogle should addFilter",tag);
        addFilter(e,tag)
      }
      else{
        console.log("toogle should removeFilter",tag);
        removeFilter(e,tag)
      }
    }

    const addFilter = async (e,tag)=> {
      store.dispatch("addSearchFilter",{filter:tag})
    }

    const removeFilter = async (e,tag)=>{
      store.dispatch("removeSearchFilter",{filter:tag})
    }

    onActivated( async ()=>{
      console.log("SearchItems got activated");
      scrollToLastPosition();
      setTimeout(initSearchItems,0); // this sucks. also working: a high number of await nextTick()
      //initSearchItems();
    })

    onMounted(async ()=>{
      //initSearchItems()
    })

    const initSearchItems = async ()=>{
      // because this is loaded as dynamic component route.query does not work
      const params = new URLSearchParams(window.location.search);
      const rootContainer = params.get("rootContainer");
      if (store.state.data.searchResultItems.length > 0 && !rootContainer) {
        console.log("no search necessary. old search results and filters still in place.");
        return
      }

      if (rootContainer){
        let externalRootContainer = await store.dispatch('getItem',rootContainer)
        console.log("externalRootContainer fetched",externalRootContainer);
        if (externalRootContainer){
          possibleRootContainers.value = [];
          possibleRootContainers.value.push(externalRootContainer);
          console.log(possibleRootContainers.value);
          if (possibleRootContainers.value.length > 0){
            store.dispatch("addSearchFilterStorage",{filter:externalRootContainer});
            //window.location.href = removeParameterFromURL('rootContainer',window.location.href);
            handleSearchButtonPressed()
          }
          else{
            store.dispatch('triggerToast',{message:"item is empty"})
          }
          return
        }
      }

      possibleRootContainers.value = await store.dispatch('getAllRootContainers');
      resetScrollPosition();
      scrollToLastPosition();
      store.dispatch("searchAllMyItems");
    }

    const searchResultsBox = ref(null);
    let lastScrollPos = 0;

    function resetScrollPosition(){
      lastScrollPos = 0;
    }

    function scrollToLastPosition(){
      searchResultsBox.value.scrollTo({
        top: lastScrollPos,
        behavior: 'instant'
      })
    }

    const trackscroll = function(e){
      lastScrollPos = e.target.scrollTop;
    }

    return {
      tagNotInChosenFilters,
      searchResultsCollapsed,
      handleChosenFilterDeleteClick,
      removeFilter,
      chosenFilters,
      possibleRootContainers,
      handleChosenFilterClick,
      searchResults,
      searchResultTags,
      handleSearchButtonPressed,
      searchTermInput,
      searchTermResults,
      addFilter,
      chosenLocations,
      handleItemClicked,
      selectModeOn,
      selectedItems,
      handleSelectAllClicked,
      handleAdd2Basket,
      handleSelectClicked,
      locationFilterVisible,
      toogleChosenFilterClick,
      searchFilterVisible,
      minWeight,maxWeight,
      minLength,maxLength,
      minWidth,maxWidth,
      minDepth,maxDepth,
      chosenArea,
      trackscroll,
      searchResultsBox,
      searchNextPage,
      loadMoreItemsButton,
      onSearchLocationFilterClose,
      onSearchFilterClose,
    }
  }
}
</script>