import { createStore } from 'vuex'
import { actions } from "./actions";
import { mutations } from "./mutations";

export default createStore({
  strict: true,
  state: {
    selected:{
      item:null,
      tabbarVisible: true,
    },
    data:{
      username: null,
      usergroups:[],
      groupmembers:[],
      userconfig:{},
      photosToPreview:null,
      photosToUpload:null,
      searchResultItems:[],
      searchResultTags:[],
      searchResultFilter: [],
      searchResultFilterStorage: [],
      searchResultFilterGeolocation: null,
      searchResultFilterRadius: null,
      scannedItems:[],
      basket:[],
      lastItemAdded: null,
    },
    ui:{
      config: (localStorage.config && JSON.parse(localStorage.config)) || {
        rememberLastStorageInAdd:false,
        cloudprinter:null,
      },
      categories:["all","cables","costume","sound","stage","props"],
      field2categoryMap:{
          length: {showIn:["cables","sound"],hideIn:["props","costume"]},
          colors: {showIn:["costume","cables"],hideIn:["stage"]},
          sizes: {showIn:["costume"],hideIn:["*"]}
      }
    }
  },
  mutations,
  actions,
  modules: {
  }
})
