/* eslint-disable no-unused-vars */
import { RestApi } from "@/services/rest";
import { useToast } from "vue-toastification";
import router from "../router";
import config from "@/../config.json";
import {dataURItoBlob, mapFormFieldsToDBFields} from "@/helpers.js"
import { v4 as uuid4 } from 'uuid';

const toast = useToast();

const api = new RestApi().initClient();

export const actions = {
  //////////////////////////////////////////////////////////////////////////////
  // GUI
  //////////////////////////////////////////////////////////////////////////////
  setSelected: async ({state,commit},{key,value})=>{
    console.log("setting selected via action",{key,value});
    commit("setSelected",({key,value}))
  },
  emptySearchResultItems: async ({state,commit})=>{
    await commit("setData",{key:"searchResultItems",value:[]})
    return true
  },
  setLastItemAdded: async ({commit},{key,value})=>{
    await commit ('setData',{key,value})
    return true
  },
  setConfigValue: async ({state, commit},{key,value})=>{
    await commit('setConfigValue',{key,value});
    console.log("action setConfigValue done",state.ui.config);
    localStorage.config = JSON.stringify(state.ui.config);
  },
  triggerToast: ({commit},{message,type})=>{
    console.log("trigger toast action",message);
    let event = new CustomEvent("triggerToast", {
      bubbles: true, 
      detail:{ toasttype: type || null, toastmessage:message} });
    document.dispatchEvent(event);
    //toast(message)
  },
  hideTabBar: async ({ commit }) => {
    commit("setSelected",({key:"tabbarVisible",value:false}))
  },
 showTabBar: async ({ commit }) => {
  commit("setSelected",({key:"tabbarVisible",value:true}))
  },
  pickItem: async ({commit},item)=>{
    commit("setSelected",({key:"item",value:item}))
  },
  add2Cart: async ({commit},{items})=>{
    let result = await commit('addBasketItems',{items});
    toast(`added ${items.length} items to basket`,{timeout:1500,toastClassName:'above-menu-bar toast-background-pink-1'});
    return result
  },
  emptyCart: async ({commit})=>{
    return commit('emptyCart')
  },
  removeItemsFromListById: async({commit},{key,items})=>{
    console.log("action removing",items,"from",key);
    commit('removeItemsFromListById',{key,items})
  },
  removeArrayDataByIndex: async({commit},{key,index})=>{
    return commit("removeArrayDataByIndex",{key,index})
  },
  removePhotosFromUploadList: async ({commit})=>{
    return commit("removePhotosFromUploadList")
  },
  //////////////////////////////////////////////////////////////////////////////
  // API
  //////////////////////////////////////////////////////////////////////////////
  testconnection: async({state,commit})=>{
    let response = await api.get(process.env.VUE_APP_API_URL);
    console.log("action testconnection got",response);
    let username = response.data.username;
    let usergroups = response.data.usergroups;
    let groupmembers = response.data.groupmembers;
    let userconfig = response.data.userconfig
    if (usergroups){
      commit('setData',{key:'usergroups',value:usergroups});
    }
    if (groupmembers){
      commit('setData',{key:'groupmembers',value:groupmembers});
    }
    if (userconfig){
      commit('setData',{key:'userconfig',value:userconfig});
    }
    if (username){
      commit('setData',{key:'username',value:username});
      return username
    }
    
  },
  signup: async ({commit},{username,email,password})=>{
    console.log("action signup",username, email, password);
    let response = await api.post(
    process.env.VUE_APP_API_URL+"/signup",{
        id:username,
        name:username,
        email,
        password
    })
    if (response && response.data.userAdded){
      toast.success("signup success. please log in!")
      setTimeout(()=>{
        window.location.reload();
      },1000)
    }
    return
  },
  login: async ({commit},{username,password})=>{
    let response = await api.post(
    process.env.VUE_APP_API_URL+"/Login",{},{
      auth: {
        username,
        password
      }
    });
    if (response.headers["authorization"] && response.headers["authorization"].startsWith("Bearer")){
      await localStorage.setItem("authtoken", response.headers["authorization"].split(" ")[1])// save token
      toast("logged in and saved token");
      commit("setData",{key:"username",value:username})
      setTimeout(()=>{
        window.location.reload();
      },1000)
    }
    return
  },
  logout:async({commit})=>{
    await localStorage.setItem("authtoken",null);
    let event = new Event("toggleAuth", { bubbles: true });
    document.dispatchEvent(event);
    return true
  },
  setUserConfig:async({state,commit})=>{
    let newconfig = state.data.userconfig; // CONTINUE HERE: add config UI to chance public_contact_details
    try{
      let updated = await api.updateUserConfig({},{newconfig})
      if (updated) toast.success("user config update success")
    }
    catch(err){
      console.error("could not set user config");
    } 
  },
  getItem: async ({ commit },id) => {
    if (!id || id.length < 1) return console.warn("no id provided. will not dispatch")
    const item =
      await api.getItem(id)
   return item.data
  },
  getItemContents: async({commit},id) =>{
    let contents =  await api.getItemContents(id);
    console.log("getItemContents",contents.data);
    return contents.data
  },
  deleteItem: async ({commit},id)=>{
    if (id.length < 1) return console.warn("no id provided. will not dispatch");
    let result = await api.deleteItem(id);
    if (result.data?.deleted){
      toast(`deleted item with id ${result.data.deleted}`)
    }
    else {
      toast.error(`could not delete item`)
    }
    return result
 },
 deleteItemsFromCart: async ({state,commit})=>{
  if (!state.data.basket || state.data.basket.length < 1) {return}
  let items2Delete = state.data.basket.map((i)=>i.id);
  console.log("itemds2Delete",items2Delete);
  for (let id of items2Delete){
    console.log("deleting",id);
    let result = await api.deleteItem(id);
    if (result.status && result.status !== 200){
      toast(`could not delete item with id ${id}`)
    }
  }
  return commit('emptyCart')
 },
 getItemRootContainer: async({commit},id)=>{
   const res = await api.getItemRootContainer(id);
   let output = res.data?.[0]
   return output
 },
 getAllRootContainers: async({commit})=>{
  const res = await api.getAllRootContainers();
  let output = res.data
  return output
  },
  searchAllMyItems: async({state,commit})=>{
    let lastItemOnPage = state.data.searchResultItems[state.data.searchResultItems.length-1]?.id;
    const itemsAndTags = await api.getItems({lastItemOnPage});
    console.log("got items",itemsAndTags, "from page starting after", lastItemOnPage);
    if (lastItemOnPage){
      console.log("adding to list");
      commit("pushData",{key:"searchResultItems",value:itemsAndTags.data.items});
    }
    else{
      console.log("first page");
      commit("setData",{key:"searchResultItems",value:itemsAndTags.data.items});
      commit("setData",{key:"searchResultTags",value:itemsAndTags.data.tags});
    }
    commit("cleanSearchFilter"); // remove all tags that are not in searchResultTags.
    if(!lastItemOnPage) return toast.success(`${itemsAndTags.data.items.length}+ items and ${itemsAndTags.data.tags.length} tags found (owned or possessed by user)`,{timeout:1000})
    if(itemsAndTags.data.items.length == 0) {
      toast.warning(`no items left`,{timeout:1000})
      return false
    }
    return toast.success(`fetched next page`,{timeout:1000})
  },
  getItems: async ({ commit }) => { // TODO: check if really ever needed?
     const items =
       await 
         api.getItems({ })
    return items.data
  },
  locateItems: async ({ commit }, {latitude,longitude,radius}) => {
    const items =
      await 
        api.locateItems({latitude,longitude,radius})
   return items.data
  },
  searchTags: async ({ commit },{tag}) => {
    const tags =
      await 
        api.searchTags({tag}) 
   return tags.data
 },// FIXME: all searchAPI methods are named poorly. rename after thinking it over!
 searchItemsAndTags: async ({ commit },{text,fillStoreWithResults}) => {
  const results =
    await 
      api.searchItems({text,fillStoreWithResults}).catch((e)=>{console.log(e);}) // TODO: add logger and error handler
    if(!results){return null}
    console.log("RESULTS",results);
    return results.data
  },
  searchItems: async ({commit},{text,
    tags,
    minWeight,maxWeight,
    minLength,maxLength,
    minWidth,maxWidth,
    minDepth,maxDepth,
    lastItemOnPage,
    containers,area,fillStoreWithResults}) => { 
    if (area){
      area = JSON.stringify(area);
    }
    let apiResult = await api.searchItemsByName({text,
        tags,
        min_weight:minWeight,max_weight:maxWeight,
        min_length:minLength,max_length:maxLength,
        min_width:minWidth,max_width:maxWidth,
        min_depth:minDepth,max_depth:maxDepth,
        containers,area,lastItemOnPage}).catch((e)=>{console.log(e);}) // TODO: add locations filter into API as optional parameter
    if (apiResult && fillStoreWithResults){
      if (lastItemOnPage){
        console.log("adding to list");
        commit("pushData",{key:"searchResultItems",value:apiResult.data.items});
      }
      else{
        commit("setData",{key:"searchResultItems",value:apiResult.data.items});
        commit("cleanSearchFilter");
        commit("setData",{key:"searchResultTags",value:apiResult.data.tags});
      }
      if(!lastItemOnPage) return toast.success(`${apiResult.data.items.length}+ items and ${apiResult.data.tags.length} tags found (owned or possessed by user)`,{timeout:1000})
      if(apiResult.data.items.length == 0) {
        toast.warning(`no items left`,{timeout:1000})
        return false
      }
    }
    
    return apiResult.data
  },
  addSearchFilter: async ({commit},filter) => {
    commit("addSearchFilter",filter)
  },
  removeSearchFilter: async ({commit},filter) => {
    commit("removeSearchFilter",filter)
  },
  addSearchFilterStorage: async ({commit},filter) => {
    commit("addSearchFilterStorage",filter)
  },
  removeSearchFilterStorage: async ({commit},filter) => {
    commit("removeSearchFilterStorage",filter)
  },
  addSearchFilterGeolocation: async ({commit},area) => {
    commit("addSearchFilterGeolocation",area)
  },
  removeSearchFilterGeolocation: async ({commit}) => {
    commit("removeSearchFilterGeolocation")
  },
  fetchItemPicture: async ({store},id) =>{
    let item = await store.dispatch('getItem',id);
    return item.pictures[0];
  },
  addItem: async ({dispatch,commit},{item}) => {
    let newItem = mapFormFieldsToDBFields(item);
    if(item.insideof || (item.atLocation && item.insideof)){
      newItem.insideof = item.insideof
    }
    else if (item.atLocation){
      newItem.atLocation = item.atLocation
    }

    if(newItem.pictures){
      // remove preview pictures (for those will be already listed in uploadedPics):
      newItem.pictures = newItem.pictures.filter((s)=>!s.startsWith("data:image/"))
    }
    
    let uploadedPics = await dispatch("uploadPhotosFromStoreAndGetUrls");
    if(uploadedPics){
      newItem.pictures = newItem.pictures.concat(uploadedPics);
      console.log("add item has picture array",newItem.pictures);
    }

    let apiResult = await api.addItem(null,newItem)
    .catch((err)=>{
      console.error("addItem error",err.response.data.message,err);
    })
    if (apiResult && apiResult.status == 200){
      dispatch("setLastItemAdded",{key:"lastItemAdded",value:newItem})
      toast.success("item added with id: " + apiResult.data.id,{ timeout: 1000 });
    }
    return apiResult
  },
  uploadPhotosFromStoreAndGetUrls: async ({state,dispatch}) => {
    console.log("about to upload",state.data.photosToUpload);
    if (state.data.photosToUpload){
      let uploaded = await dispatch("uploadPhotos");
      if (uploaded.status !== 200){
        toast.error("error uploading pictures");
        throw 'could not upload the pictures'
      }
      let files = uploaded.data.files;
      console.log("uploadPhotosFromStoreAndGetUrls successfully uploaded images to server", files);
      return files;
    }
  },
  editItem: async ({commit,dispatch},{item}) => {
    let newItem = mapFormFieldsToDBFields(item);
    if(item.insideof){
      console.log("action edititem ignoring atLocation because insideof exists");
      newItem.insideof = item.insideof
    }
    else if (item.atLocation){
      console.log("action edititem using atLocation because insideof null");
      newItem.atLocation = item.atLocation
    }

    if(newItem.pictures){
      // remove preview pictures (for those will be already listed in uploadedPics):
      newItem.pictures = newItem.pictures.filter((s)=>!s.startsWith("data:image/"))
    }

    let uploadedPics = await dispatch("uploadPhotosFromStoreAndGetUrls");
    if(uploadedPics){
      newItem.pictures = newItem.pictures.concat(uploadedPics);
      // refetch uploaded pictures to break cache without reload for following screens:
      newItem.pictures.map((pic)=>fetch(pic))
    }
    try{
      let apiResult = await api.updateItem(item.id,newItem);
      return apiResult;
    }
    catch(e){
      return e
    }
    
  },
  setPhotosToUpload: async ({commit},{files}) => {
    commit("setData",{key:"photosToUpload", value:files}) // CONTINUE HERE: TODO: add action to append Photo to FileList using DataTransfer Object
  },
  setPhotosToPreview: async ({commit},{files}) => {
    commit("setData",{key:"photosToPreview", value:files})
  },
  getPhotoFileNameFromPreviewIndex: async({state},index)=>{
    console.log(state.selected.item);
    let filename = state.selected.item.pictures[index];
    console.log("getPhotoFileNameFromPreviewIndex",index);
    return filename
  },
  writeDataUrlIntoFileList: async({commit,state},{dataUrl,filename="newFile.png"})=>{
    let newFile = new File([dataURItoBlob(dataUrl)],filename);
    const dT = new DataTransfer();
    if(state.data.photosToUpload && state.data.photosToUpload.length > 0){
      for (let i = 0; i < state.data.photosToUpload.length; i++){
        console.log("writeDataUrlIntoFileList","getting file from photosToUpload state",i);
        if (state.data.photosToUpload[i].name == filename) continue // skip files of same name as new file
        dT.items.add(state.data.photosToUpload[i]);
      }
    }
    dT.items.add(newFile);
    commit("setData",{key:"photosToUpload", value:dT.files})
    console.log("writeDataUrlIntoFileList","set photosToUpload with dataURL to:",state.data.photosToUpload);
  },
  /**
   * 
   * API action to make /upload endpoint work. 
   * This endpoint uses javascripts formData API which was too hard for me to implement into the OpenAPI-axios-client.
   * It takes the FileList from store.state.data.photosToUpload and uploads all of them to the 
   * server endpoint /upload
   */
  uploadPhotos: async ({commit,state}) =>{
    let formData = new FormData();
    for (var i = 0; i < state.data.photosToUpload.length; i++ ){
      let file = state.data.photosToUpload[i];
      let filename = file.name;
      if (!filename.startsWith("_dingsda2mex_")){
        filename = "_dingsda2mex_"+uuid4()+"_"+file.name
      }
      let newFile = new File([file],filename, {type: file.type});
      formData.append('file' + i + '', newFile);
    }
    return await api.post( '/upload',
      formData,
      {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      }
    ).then(function(result){
      commit("setData",{key:'photosToUpload',value:null})
      return result
    })
    .catch(function(e){
      throw new Error("could not upload files") 
    });
  },
  moveItems: async ({commit},{items})=>{
    console.log("move items items:",items);
    if (!items || items.length == 0) return toast.error("no items selected")

    
    let moved = await api.moveItems({},{items}).catch((e)=>{
      console.log("error moving items",e);
    });
    if (moved.status == 200){
      console.log("moved items",moved);
      toast.success("moved items");
    }
    return moved
  },
  addMember:async({commit},{id})=>{
    if (!id) return console.warn("no member id provided");
    let memberAddedRes = await api.addMemberToMyself({},{memberid:id}).catch((err)=>{
      console.error('issue adding member 2 user:',err)
     })
    if (memberAddedRes && memberAddedRes.data){
      toast.success("success: member added")
      return true
    }
    return false
   },
   deleteMember:async({commit},{id})=>{
     if (!id) return console.warn("no member id provided");
     let memberDeletedRes = await api.deleteMemberFromMyself({},{memberid:id}).catch((err)=>{
       console.error('issue adding member 2 user:',err)
      })
     if (memberDeletedRes && memberDeletedRes.data){
       toast.success("success: member deleted")
       return true
     }
     return false
    },
   cloudPrint:async ({state, commit},{qrcodes})=>{
     console.log('cloudprinting',qrcodes,state.ui.config.cloudprinter);
     if (!qrcodes){return console.warn("no qrcodes provided");}
     let oldqueueRes = await api.getPrinterQueue({id:state.ui.config.cloudprinter}).catch((err)=>{
       console.error('issue getting cloudprinter queue:',err)
     })
     let oldqueue = oldqueueRes.data;
     console.log(oldqueue);
     if (oldqueue && oldqueue.length > 0){
       qrcodes = oldqueue.concat(qrcodes);
     }
     let response = await api.setPrinterQueue({id:state.ui.config.cloudprinter},{prints:qrcodes})
     return response
   },
   handoverItem: async (commit,{item_id,to})=>{
     console.log(`handover action item ${item_id} to ${to}`,to);
     let res = await api.handoverItem({item:item_id},{to})
     if (res.data.handover){
       toast.success("handover completed");
     }
     return res.data
   },
   getItemOwnersContactDetails: async (commit,id)=>{
     let res = await api.ask2borrow({item:id})
     return res.data
   },
//////////////////////////////////////////////////////////////////////////////
// 3rd PARTY APIs
//////////////////////////////////////////////////////////////////////////////

  reverseGeoCode:async({commit},{latitude,longitude})=>{
    const tileproviderKey = config.maptilerKey;
    const reverseGeocodingUrl = `https://api.maptiler.com/geocoding/${longitude},${latitude}.json?key=${tileproviderKey}`
    
    let res = await fetch(reverseGeocodingUrl).then((response) => {
       return response.json()
    })
    
    if (res && res.features){
      console.log(res.features[0].text);
      return res.features[0].text
    }
    return null
  },
  forwardGeoCode:async({commit},searchtext)=>{ 

    //const tileproviderKey = config.maptilerKey;
    //const reverseGeocodingUrl = `https://api.maptiler.com/geocoding/${searchtext}.json?key=${tileproviderKey}&language=de`
     const tileproviderKey = config.mapboxAccessToken;
     const reverseGeocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchtext}.json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=${tileproviderKey}`

    let res = await fetch(reverseGeocodingUrl).then((response) => {
      return response.json()
    })
    
    if (res && res.features){
      console.log(res.features);
      return res.features
    }
    return null
  }

};



