<template>
   <div class="qrcode"  ref="canvas" :dingsda-qr="content">
     <!-- <div class="qrcodelabel">{{content}}<br></div> -->
      <!-- <div ref="canvas"></div> -->
   </div>
</template>

<script>

import QRCodeStyling from 'qr-code-styling'
import {  onMounted, ref,  } from '@vue/runtime-core';

export default {
  name: "QRCode",
  props: ["content"],
  emits: [],
  components: { },
  setup(props) {

     const canvas = ref(null);
     let qrCode;
     const qrCodeOptions =  ref({
              "width":300,
              "height":300,
              "data":props.content,
              "margin":0,
              "qrOptions":{
                "typeNumber":"0",
                "mode":"Byte",
                "errorCorrectionLevel":"Q"
              },
              "imageOptions":{
                "hideBackgroundDots":true,
                "imageSize":0.5,
              },
              // "dotsOptions":{
              //   "type":"dots",
              //   "color":"#000000"
              // },
              // "image":"logo_small.svg",
              // "cornersSquareOptions":{
              //   "type":"extra-rounded",
              //   "color":"black",
              // },
      })

    

    onMounted(()=>{
         updateQRCode()
    })
     
    function updateQRCode(){
       qrCode = new QRCodeStyling(
           qrCodeOptions.value
        );

        qrCode.append(canvas.value);
        //qrCode.download({ name: "qr", extension: "svg" });
    }

    return {canvas};
  },
};
</script>
