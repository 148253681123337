<template>
  <div class="mb-menu-bar">
    <Config />
    <button class="button mt-4" @click="$store.dispatch('logout')">LOGOUT</button>
    <hr>
  </div>
  <TabBar />
</template>

<script>
import TabBar from '@/components/Page/TabBar.vue'
import Config from '@/components/ItemForm/Config.vue'
import { useStore } from 'vuex'

export default {
  name: 'Landing',
  components:{
    TabBar,
    Config,
  },
  props: {
    msg: String
  },
  setup(){
    const store = useStore();

    console.log(store.state.data);
  }
}
</script>
